import React, { lazy, Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom";

// plugins styles from node_modules
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/css/global.css";
import AuthRoute from "./components/PrivateRoute/AuthRoute";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import config from "./config";
// import DashboardRoute from "components/PrivateRoute/DashboardRoute";
import { UserDataProvider } from "./UserDataContext";
import LoadingPage from "./views/LoadingPage";

const ExternalRedirect = lazy(
  () => import("./views/pages/components/ExternalRedirect"),
);
const Vendors = lazy(() => import("./views/Vendors/Vendors.js"));
const PartCreate = lazy(() => import("./views/Parts/PartCreate.js"));
const Parts = lazy(() => import("./views/Parts/Parts"));
const Documents = lazy(() => import("./views/Documents/Documents"));

const DocumentsInvoice = lazy(
  () => import("./views/Documents/DocumentsInvoice.tsx"),
);
const DocumentsDetailsInvoice = lazy(
  () => import("./views/Documents/DocumentsDetailsInvoice.tsx"),
);

const DocumentsAck = lazy(() => import("./views/Documents/DocumentsAck.tsx"));
const DocumentsDetailsAck = lazy(
  () => import("./views/Documents/DocumentsDetailsAck.tsx"),
);

const DocumentsInvoiceLogistics = lazy(
  () => import("./views/Documents/logistics/DocumentsInvoiceLogistics.tsx"),
);
const DocumentsDetails = lazy(
  () => import("./views/Documents/DocumentsDetails"),
);
const DocumentsDetailsInvoiceLogistics = lazy(
  () =>
    import("./views/Documents/logistics/DocumentsDetailsInvoiceLogistics.tsx"),
);
const ChatSetPage = lazy(() => import("./views/Orders/ChatSetPage"));
const ContractPage = lazy(() => import("./views/ContractPage"));
const SearchChatbotPage = lazy(() => import("./views/SearchChatbotPage"));
const SearchChatbotPageD = lazy(() => import("./views/SearchChatbotPageDemo"));
const SearchChatbotPageDS = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan"),
);
const SearchChatbotPageDS2 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan2"),
);
const SearchChatbotPageDS3 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan3"),
);
const SearchChatbotPageDS4 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan4"),
);
const SearchChatbotPageDS5 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan5"),
);
const SearchChatbotPageDS6 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan6"),
);
const AnalyticsChatbotPageDS6 = lazy(
  () => import("./views/AnalyticsChatbotPageDemo-srijan6"),
);
const SearchChatbotPageDS7 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan7"),
);

const SearchChatbotPageDS8 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan9"),
);
const SearchChatbotPageDS11 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan11"),
);

// const InvoiceChatbotPageDS8 = lazy(
//   () => import("./views/SearchChatbotPageDemo-srijan8"),
// );

const AllProductsPage = lazy(() => import("./views/Parts/Parts-subframe.tsx"));
const AllOrdersPage = lazy(
  () => import("./views/Orders/AllOrders-subframe.tsx"),
);
const OrdersChatbot = lazy(() => import("./views/Orders/OrdersChatbot.tsx"));
const OneProductPage = lazy(() => import("./views/Parts/OnePart-subframe.tsx"));
const OrderVendorsPage = lazy(
  () => import("./views/Orders/OrderVendorsPage.tsx"),
);

const Order = lazy(() => import("./views/Order/Order.tsx"));
const DashboardPage = lazy(() => import("./views/Dashboard"));
const SpendIntelligence = lazy(() => import("./views/SpendIntelligence.tsx"));
const SpendIntelligenceSoftware = lazy(
  () => import("./views/SpendIntelligenceSoftware.tsx"),
);
const PurchasingIntelligence = lazy(
  () => import("./views/PurchasingIntelligence"),
);
const PurchasingIntelligenceSoftware = lazy(
  () => import("./views/PurchasingIntelligenceSoftware"),
);

const RfqVsQuoteChecker = lazy(
  () => import("./views/Widgets/RfqVsQuoteChecker"),
);
const InvoiceVsPOChecker = lazy(
  () => import("./views/Widgets/InvoiceVsPOChecker"),
);
const InvoiceVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/InvoiceVsPOCheckerDemo"),
);

const InvoiceVsContractCheckerDemo = lazy(
  () => import("./views/Widgets/InvoiceVsContractCheckerDemo"),
);

const AcknowledgementVsPOChecker = lazy(
  () => import("./views/Widgets/AcknowledgementVsPOChecker"),
);
const PackingSlipVsPOChecker = lazy(
  () => import("./views/Widgets/PackingSlipVsPOChecker"),
);
const PackingSlipVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/PackingSlipVsPOCheckerDemo"),
);
const A = lazy(() => import("./views/Widgets/a"));
const POUpload = lazy(() => import("./views/Widgets/POUploadPage"));
const QuoteToPO = lazy(() => import("./views/POs/QuoteToPO.tsx"));
// const POtoAck = lazy(() => import("./views/POs/POtoAck.tsx"));
const POTable = lazy(() => import("./views/POs/POTable.tsx"));
// const SinglePOForm = lazy(() => import("./views/POs/SinglePOForm.tsx"));
const SpreadsheetToPOAshley = lazy(
  () => import("./views/POs/Spreadsheet2PO-Ashley.tsx"),
);
const SpreadsheetToPOJessica = lazy(
  () => import("./views/POs/Spreadsheet2PO-Jessica.tsx"),
);
// const SpreadsheetToPOJessicaExcel = lazy(() => import("./views/POs/Spreadsheet2PO-Jessica-Excel.tsx"));
const SearchAvailableMaterialId = lazy(
  () => import("./views/SearchAvailableMaterialId.tsx"),
);
const StartRFQForm = lazy(() => import("./views/RFQ/StartRFQForm.tsx"));
const SinglePOForm = lazy(() => import("./views/POs/SinglePOForm.tsx"));
const BOManalysis = lazy(() => import("./views/bom/BOManalysis.tsx"));

const AuthLayout = lazy(() => import("./layouts/Auth.js"));
const TermsView = lazy(() => import("./views/Terms.js"));
const PrivacyView = lazy(() => import("./views/Privacy.js"));
const SuccessPage = lazy(() => import("./views/Success.tsx"));

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <GoogleOAuthProvider clientId={config.GoogleSSO_CLIENT_ID}>
    <UserDataProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <AuthRoute path="/auth" component={AuthLayout} />
            <Route path="/terms" component={TermsView} />
            <Route path="/privacy" component={PrivacyView} />
            <Route path={"/success"} component={SuccessPage} />

            <PrivateRoute path={"/parts/new"} component={PartCreate} />

            <PrivateRoute path={"/orders/newchat"} component={OrdersChatbot} />
            <PrivateRoute
              path={"/parts-old/:part_id/vendors"}
              component={Vendors}
            />
            <PrivateRoute path={"/parts-old"} component={Parts} />
            <PrivateRoute
              path={"/parts/:part_id/vendors"}
              component={OneProductPage}
            />
            <PrivateRoute path={"/parts"} component={AllProductsPage} />

            <PrivateRoute
              path={"/orders/:set_id/vendors"}
              component={OrderVendorsPage}
            />

            <PrivateRoute path={"/order/:order_id"} component={Order} />

            <PrivateRoute path={"/orders"} component={AllOrdersPage} />

            <PrivateRoute path={"/order-chat/:setId"} component={ChatSetPage} />

            <PrivateRoute
              path={"/documents/invoice/:alertId"}
              component={DocumentsDetailsInvoice}
            />
            <PrivateRoute
              path={"/documents/invoice"}
              component={DocumentsInvoice}
            />

            <PrivateRoute
              path={"/documents/ack/:alertId"}
              component={DocumentsDetailsAck}
            />
            <PrivateRoute path={"/documents/ack"} component={DocumentsAck} />

            <PrivateRoute
              path={"/documents/invoice-logistics/:alertId"}
              component={DocumentsDetailsInvoiceLogistics}
            />
            <PrivateRoute
              path={"/documents/invoice-logistics"}
              component={DocumentsInvoiceLogistics}
            />

            <PrivateRoute
              path={"/documents/:alertId"}
              component={DocumentsDetails}
            />
            <PrivateRoute path={"/documents"} component={Documents} />
            <PrivateRoute path={"/chatbot"} component={SearchChatbotPage} />
            <PrivateRoute path={"/chatbotd"} component={SearchChatbotPageD} />
            <PrivateRoute path={"/chatbotds"} component={SearchChatbotPageDS} />
            <PrivateRoute
              path={"/chatbotds2"}
              component={SearchChatbotPageDS2}
            />
            <PrivateRoute
              path={"/chatbotds3"}
              component={SearchChatbotPageDS3}
            />
            <PrivateRoute
              path={"/chatbotds4"}
              component={SearchChatbotPageDS4}
            />
            <PrivateRoute
              path={"/chatbotds5"}
              component={SearchChatbotPageDS5}
            />
            <PrivateRoute
              path={"/chatbotds6"}
              component={SearchChatbotPageDS6}
            />
            <PrivateRoute
              path={"/analyticschatbot"}
              component={AnalyticsChatbotPageDS6}
            />
            <PrivateRoute
              path={"/chatbotds7"}
              component={SearchChatbotPageDS7}
            />

            <PrivateRoute
              path={"/chatbotds8"}
              component={SearchChatbotPageDS8}
            />
            <PrivateRoute
              path={"/chatbotds11"}
              component={SearchChatbotPageDS11}
            />

            <Route path={"/quotecheck"} component={RfqVsQuoteChecker} />

            <PrivateRoute path={"/newRFQ"} component={StartRFQForm} />

            <PrivateRoute path={"/quote2PO"} component={QuoteToPO} />
            {/*<PrivateRoute path={"/PO2Ack"} component={POtoAck} />*/}
            {/* <PrivateRoute path={"/newPO"} component={SinglePOForm} /> */}

            <PrivateRoute path={"/bom"} component={BOManalysis} />

            <PrivateRoute
              path={"/spreadsheet2PO"}
              component={SpreadsheetToPOAshley}
            />
            <PrivateRoute
              path={"/spreadsheet2PO-ashley"}
              component={SpreadsheetToPOAshley}
            />

            <PrivateRoute
              path={"/spreadsheet2PO-jessica"}
              component={SpreadsheetToPOJessica}
            />

            {/* <PrivateRoute
              path={"/spreadsheet2PO-jessica-excel"}
              component={SpreadsheetToPOJessicaExcel}
            /> */}

            <PrivateRoute
              path={"/find-open-mim"}
              component={SearchAvailableMaterialId}
            />

            <PrivateRoute path={"/POform"} component={SinglePOForm} />

            <PrivateRoute
              path={"/invoiceverify"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/quote-upload"}
              component={RfqVsQuoteChecker}
            />
            <PrivateRoute
              path={"/invoice-upload"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/invoice-uploadd"}
              component={InvoiceVsPOCheckerDemo}
            />

            <PrivateRoute
              path={"/invoice-contract-compare-demo"}
              component={InvoiceVsContractCheckerDemo}
            />

            <PrivateRoute
              path={"/acknowledgement-upload"}
              component={AcknowledgementVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-upload"}
              component={PackingSlipVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-uploadd"}
              component={PackingSlipVsPOCheckerDemo}
            />
            <PrivateRoute path={"/acknowledgement-uploadd"} component={A} />
            <PrivateRoute path={"/po-upload"} component={POUpload} />
            <PrivateRoute
              path={"/po/:req_number/:ponumber"}
              component={POTable}
            />

            <PrivateRoute
              path={"/spendintelligence-software"}
              component={SpendIntelligenceSoftware}
            />
            <PrivateRoute
              path={"/spendintelligence"}
              component={SpendIntelligence}
            />
            <PrivateRoute
              path={"/purchasingintelligence"}
              component={PurchasingIntelligence}
            />
            <PrivateRoute
              path={"/purchasingintelligence-software"}
              component={PurchasingIntelligenceSoftware}
            />
            <PrivateRoute path={"/contract"} component={ContractPage} />
            <PrivateRoute path="/" component={DashboardPage} exact />
            <Route path="*" render={() => <ExternalRedirect url="/orders" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </UserDataProvider>
  </GoogleOAuthProvider>,
);
